<template>
  <div v-if="labelType == false && !estUnPack" class="composant-saisie-stock">
    <!-- <div class="type-saisie">{{ typeSaisie }}</div> -->
    <input
      @change="updateTotal"
      @focus="focusEvents"
      @blur="blurEvents"
      v-model="inputStock.cartons_unite_festival"
      class="input-saisie-stock"
      type="number"
      min="0"
      :disabled="inputStock.verrouillage_unite_festival == 1 || festivalCloture"
    />
    <input
      @change="updateTotal"
      @focus="focusEvents"
      @blur="blurEvents"
      v-model="inputStock.ramettes_unite_festival"
      class="input-saisie-stock"
      type="number"
      min="0"
      :disabled="inputStock.verrouillage_unite_festival == 1 || festivalCloture"
    />
    <input
      @change="updateTotal"
      @focus="focusEvents"
      @blur="blurEvents"
      v-model="inputStock.libres_unite_festival"
      class="input-saisie-stock"
      type="number"
      min="0"
      :disabled="inputStock.verrouillage_unite_festival == 1 || festivalCloture"
    />
    <input :value="totalStockSaisi" class="input-saisie-stock" type="number" min="0" disabled />
    <div v-if="displayLock" class="frameCadenas">
      <img
        class="imgCadenas"
        :src="imgVerrouillage"
        alt="verrouillage_unite_festival"
        @click="inverseVerrouillage"
      />
    </div>
  </div>
  <div v-else-if="labelType == false && estUnPack" style="display: flex; height: 100%; align-items: center;">
    <div>
      <div class="composant-saisie-stock">
        <div class="label-type-coffret">Coffret plein :</div>
        <input
          @change="updateTotal"
          @focus="focusEvents"
          @blur="blurEvents"
          v-model="inputStock.pack_plein_unite_festival"
          class="input-saisie-stock"
          type="number"
          min="0"
          :disabled="inputStock.verrouillage_unite_festival == 1 || festivalCloture"
        />
      </div>
      <div class="composant-saisie-stock">
        <div class="label-type-coffret">Coffret vide :</div>
        <input
          @change="updateTotal"
          @focus="focusEvents"
          @blur="blurEvents"
          v-model="inputStock.pack_vide_unite_festival"
          class="input-saisie-stock"
          type="number"
          min="0"
          :disabled="inputStock.verrouillage_unite_festival == 1 || festivalCloture"
        />
      </div>
    </div>
    <div v-if="displayLock" class="frameCadenas">
      <img
        class="imgCadenas"
        :src="imgVerrouillage"
        alt="verrouillage_unite_festival"
        @click="inverseVerrouillage"
      />
    </div>
  </div>

  <!-- Si il faut afficher uniquement les labels -->
  <div v-else-if="labelType == true && !estUnPack" class="composant-saisie-stock">
    <div class="labels-desktop">
      <div class="input-saisie-stock" type="number">Cartons</div>
      <div class="input-saisie-stock" type="number">Ramettes</div>
      <div class="input-saisie-stock" type="number">Libres</div>
      <div class="input-saisie-stock">Total</div>
    </div>
    <div class="labels-mobile">
      <div class="input-saisie-stock">
        <img src="assets/icones/icone-carton.png" alt="carton" class="icones" />
      </div>
      <div class="input-saisie-stock">
        <img src="assets/icones/icone-ramette.png" alt="ramette" class="icones" />
      </div>
      <div class="input-saisie-stock">
        <img src="assets/icones/icone-libres.png" alt="libres" class="icones" />
      </div>
      <div class="input-saisie-stock">
        <img src="assets/icones/icone-chariot.png" alt="total" class="icones" />
      </div>
    </div>
  </div>
  <div v-else-if="labelType == true && estUnPack" class="composant-saisie-stock">
    <div class="labels-desktop">
       <div class="label-type-coffret"></div>
      <div class="input-saisie-stock" type="number">Quantité</div>
    </div>
    <div class="labels-mobile">
      <div class="input-saisie-stock">
        <img src="assets/icones/icone-libres.png" alt="libres" class="icones" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ComposantSaisieStock",
  props: {
    festivalCloture: { default: false },
    displayLock: { default: true },
    typeSaisie: { default: "" },
    totalVModel: { default: 0 },
    labelType: { default: false },
    estUnPack: { default: false },
    type_unite_festival: {
      default: undefined,
    },
    checkVerrouillage: {
      type: Function,
      default: () => {
        console.error(
          "composantSaisieStock: Aucune fonction de vérification de verrouillage n'a été transmise"
        );
      },
    },
    detailsIcone: {
      default: `Statut saisie
        ❌ : saisie avant départ à faire
        🕒 : saisie après retour à faire
        ✔️ : toutes les saisies ont été effectuées`,
    },
  },
  model: {
    prop: "totalVModel",
    event: "update",
  },
  data: () => {
    return {
      INDEX_CARTONS: 0,
      INDEX_RAMETTES: 1,
      INDEX_LIBRES: 2,
      verrouillage_unite_festival: false,
      totalStockSaisi: 0,
      protectContent: false,
      inputStock: {
        cartons_unite_festival: 0,
        ramettes_unite_festival: 0,
        libres_unite_festival: 0,
        verrouillage_unite_festival: 0,
      },
      // inputStock: () => [
      //   this.totalVModel.cartons_unite_festival,
      //   this.totalVModel.ramettes_unite_festival,
      //   this.totalVModel.libres_unite_festival,
      // ],
    };
  },
  computed: {
    ...mapState(["imgVerrouillageConst", "UNITE_FESTIVAL_DEPART", "UNITE_FESTIVAL_RETOUR"]),
    imgVerrouillage() {
      return this.imgVerrouillageConst.get(this.inputStock.verrouillage_unite_festival == 1);
    },
  },
  methods: {
    focusEvents(e) {
      e.target.select();
      this.protectContent = true;
    },
    blurEvents() {
      this.protectContent = false;
    },

    inverseVerrouillage() {
      this.inputStock.verrouillage_unite_festival =
        this.inputStock.verrouillage_unite_festival == 0 ? 1 : 0;
      this.vModelSync();
      this.$nextTick(() => {
        this.checkVerrouillage();
      });
    },
    vModelSync() {
      this.$emit("update", this.inputStock);
    },
    updateTotal() {
      for (let index of [
        "cartons_unite_festival",
        "ramettes_unite_festival",
        "libres_unite_festival",
      ]) {
        if (isNaN(this.inputStock[index])) this.inputStock[index] = 0;
      }
      this.calculStockTotal();
      this.vModelSync();
    },
    calculStockTotal() {
      this.totalStockSaisi =
        parseInt(this.inputStock.cartons_unite_festival) * 150 +
        parseInt(this.inputStock.ramettes_unite_festival) * 50 +
        parseInt(this.inputStock.libres_unite_festival);
    },
  },
  watch: {
    totalVModel() {
      if (this.protectContent != true) {
        this.inputStock = { ...this.totalVModel };
        this.calculStockTotal();
      }
    },
  },
  mounted: function() {
    this.$nextTick(() => {
      this.inputStock = { ...this.totalVModel };
      this.calculStockTotal();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-saisie-stock {
  @include typo-festival;
  // width: 60px;
  // width: 500px;
  height: 30px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.type-saisie {
  width: 100px;
  text-align: center;
  // height: 20px;
  &.large {
    font-size: 1.5em;
  }
  @media (max-width: $mobile-break-point) {
    display: none;
  }
}

.frameCadenas {
  // width: 30px;
  // height: 25px;
}

.imgCadenas {
  height: 25px;
  cursor: pointer;
  position: relative;
  // bottom: 4px;
  margin-left: 10px;
}

.input-saisie-stock {
  // width: inherit;
  margin: 0;
  margin-right: 5px;
  text-align: center;
  width: 80px;
  @media (max-width: $mobile-break-point) {
    width: 60px;
  }
  // height: 25px;
}

.label-type-coffret{
  width: 120px;
}

.labels-desktop {
  display: flex;
  @media (max-width: $mobile-break-point) {
    display: none;
  }
}

.labels-mobile {
  display: none;
  @media (max-width: $mobile-break-point) {
    display: flex;
  }
}

.icones {
  height: 25px;
}
</style>
